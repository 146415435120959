var firebaseConfig = {
  apiKey: "AIzaSyDyHr7ccD2c4m57zTYuBCLPG2bkl0_9LWA",
  authDomain: "pickaapp-79307.firebaseapp.com",
  databaseURL: "https://pickaapp-79307.firebaseio.com",
  projectId: "pickaapp-79307",
  storageBucket: "pickaapp-79307.appspot.com",
  messagingSenderId: "751464223460",
  appId: "1:751464223460:web:56eb8dd441adca7fde12f1",
  measurementId: "G-SECVQFXM33"
};

export { firebaseConfig }