import './assets/css/style.min.css'

import { initializeApp } from "firebase/app";
import { firebaseConfig } from './components/config';
import { User } from './components/user';

const app = initializeApp(firebaseConfig)

const user = new User(app)

user.init((user) => {
    // do user stuff here
}, () => { location.href = "./account/login/" })

require("./assets/js/scripts")

